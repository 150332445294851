import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Resource from '../components/Resource';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Icon from '../components/Icon';
import OutboundLink from '../components/OutboundLink';

const IndexPage = ({ data }) => (
  <Layout title="About the Program">
    <Section>
      <Row>
        <Column size={12} className="about-header">
          <h1 className="page-header">About</h1>
          <Image
            filename="about-graphic-greenellipse-hero.svg"
            className="green-circle"
          />
          <Image
            filename="about-graphic-yellowellipse-hero.svg"
            className="yellow-circle"
          />
          <Image
            filename="about-graphic-lineart-hero.svg"
            className="about-hero-bg"
          />
          <Image
            filename="about-image-boyright-hero.png"
            className="about-hero-boy"
          />
          <Image
            filename="about-image-girlleft-hero.png"
            className="about-hero-girl"
          />
        </Column>
      </Row>
    </Section>
    <Section className="about-mission">
      <Container>
        <Row>
          <Column size={4}>
            <h2 className="cat-h2">Mission</h2>
            <p>
              The natural sparks of creativity and curiosity inside every
              student are key to empowering the next generation of bold
              thinkers, leaders, and skilled STEM professionals. Innovation At
              Play provides equitable learning resources celebrating the power
              of play to engage students from every background in the
              life-changing careers of tomorrow. Join us as we equip students
              nationwide with the problem-solving tools needed to build lifelong
              success.
            </p>
          </Column>
          <Column size={8}>
            <Image filename="mission-image-about.png" />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="about-partners">
      <Container>
        <Row className="section-image-header">
          <Column size={6}>
            <h2>About the Partners</h2>
          </Column>
          <Column size={6}>
            <Image filename="about-graphic-lineart-partners.svg" />
          </Column>
        </Row>
        <Row className="about-partner">
          <Column size={4}>
            <OutboundLink
              to="https://www.caterpillarfoundation.org"
              target="_blank"
            >
              <Image
                filename="logos/CAT_Foundation_Logo.svg"
                className="about-partner-logo about-partner-logo--de"
                alt="Caterpillar Foundation"
              />
            </OutboundLink>
          </Column>
          <Column size={8}>
            <p>
              Caterpillar is the world’s leading manufacturer of construction
              and mining equipment, diesel and natural gas engines, industrial
              gas turbines, and diesel-electric locomotives. With a commitment
              to solutions that help customers and communities build a better
              world, and a history of innovation dating back over 100 years,
              Caterpillar has become the largest global presence in the
              industries it serves. The Caterpillar Foundation is dedicated to
              creating the human, natural, and basic services infrastructure
              needed to advance sustainable progress and improve living
              standards worldwide. 
            </p>
            <OutboundLink
              to="https://www.caterpillarfoundation.org"
              target="_blank"
            >
              Visit CaterpillarFoundation.org
              <Icon name="externallink" marginLeft />
            </OutboundLink>
          </Column>
        </Row>
        <Row className="about-partner">
          <Column size={4}>
            <OutboundLink
              to="https://www.learningundefeated.org"
              target="_blank"
            >
              <Image
                filename="logos/LearningUndefeated-logo-tag.svg"
                className="about-partner-logo about-partner-logo--de"
                alt="Learning Undefeated"
              />
            </OutboundLink>
          </Column>
          <Column size={8}>
            <p>
              Learning Undefeated is driving race and gender equity in STEM
              through experiential and deep-impact learning experiences for
              students from under resourced communities. Best known for its
              fleet of traveling STEM laboratories, Learning Undefeated’s
              flagship STEM education program has prepared more than 1.3 million
              K-12 students for STEM careers since 2003, through hands-on
              content exploration, game-based learning, summer camps, and talent
              development programs. In 2022, Learning Undefeated’s shipping
              container based Drop Anywhere Lab won the Best of STEM Awards in
              the Social Impact: Promoting Diversity, Equity & Inclusion
              category. Learning Undefeated also operates several other
              celebrated STEM education programs including custom curriculum
              development and STEM disaster recovery education.
            </p>
            <OutboundLink
              to="https://www.learningundefeated.org"
              target="_blank"
              className="text-bold"
            >
              Visit LearningUndefeated.org
              <Icon name="externallink" marginLeft />
            </OutboundLink>
          </Column>
        </Row>
        <Row className="about-partner">
          <Column size={4}>
            <OutboundLink
              to="https://www.discoveryeducation.com"
              target="_blank"
            >
              <Image
                filename="de-logo-positive.png"
                className="about-partner-logo about-partner-logo--de"
                alt="Discovery Education"
              />
            </OutboundLink>
          </Column>
          <Column size={8}>
            <p>
              Discovery Education is the global leader in standards-aligned
              digital curriculum resources, engaging content, and professional
              learning for K-12 classrooms. Through its award-winning digital
              textbooks, multimedia resources, and the largest professional
              learning network of its kind, Discovery Education is transforming
              teaching and learning, creating immersive STEM experiences, and
              improving academic achievement around the globe. Discovery
              Education currently serves approximately 4.5 million educators and
              45 million students worldwide, and its resources are accessed in
              over 140 countries and territories. Inspired by the global media
              company Warner Bros. Discovery, Inc., Discovery Education partners
              with districts, states, and like-minded organizations to empower
              teachers with customized solutions that support the success of all
              learners.
            </p>
            <OutboundLink
              to="https://www.discoveryeducation.com"
              target="_blank"
              className="text-bold"
            >
              Visit Discoveryed.com
              <Icon name="externallink" marginLeft />
            </OutboundLink>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <div className="about-additional-resources">
          <Row>
            <Column size={12}>
              <h2 className="about-additional-resources__heading">
                Additional resources (STEM Careers Coalition)
              </h2>
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              <Resource
                img={'about-image-students-addlresources.png'}
                subjects={['Classroom Activity']}
                title={'Students'}
                audience="What kind of problems do you want to solve? "
                duration=""
                description="Explore the profiles below of people just like you who are using the skills they learned in school to become the kind of problem solvers that make a difference. Make the connection from the classroom to careers."
                actions={[
                  {
                    label: 'Learn More',
                    link:
                      'https://stemcareerscoalition.org/after-school-activities',
                    target: '_blank',
                  },
                ]}
              />
            </Column>
            <Column size={6}>
              <Resource
                img={'about-image-educators-addlresources.png'}
                subjects={['Classroom Activity']}
                title={'Educators'}
                audience="How can you inspire the workforce of tomorrow today?"
                duration=""
                description="Access the no-cost, standards-aligned classroom activities to support future career success for all students. Each activity features important STEM skills and a step-by-step guide for implementing activities into your classroom. Explore the resources today."
                actions={[
                  {
                    label: 'Learn More',
                    link:
                      'https://stemcareerscoalition.org/educators/classroom-activities',
                  },
                ]}
              />
            </Column>
          </Row>
        </div>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Column size={12} className="footer-image-column about">
            <div className="text-overlay">
              <h2>
                Scientific creativity and artistic creativity are dependent on
                the same three essential factors: openness, creative
                self-efficacy, and divergent thinking.{' '}
              </h2>
              <h3>– University of Southern Australia</h3>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
